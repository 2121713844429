<template>
  <div class="realnameauth">
    <mt-header :title="$t('personalInfo')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <step-item :step="1" />
    <div class="content">
      <div class="item">
        <div class="title">{{ $t('firstName') }}</div>
        <mt-field
          :placeholder="$t('firstNamePlaceholder')"
          :state="firstNameState"
          v-model="form.firstName"
          @blur.native.capture="BEHAVIOR_ADD({
            id: 'P02_C01_I_FIRSTNAME',
            newValue: form.firstName
          })"
        ></mt-field>
      </div>
      <div class="item">
        <div class="title optional">{{ $t('middleName') }}</div>
        <mt-field
          :placeholder="$t('middleNamePlaceholder')"
          :state="middleNameState"
          v-model="form.middleName"
          @blur.native.capture="BEHAVIOR_ADD({
            id: 'P02_C02_I_MIDDLENAME',
            newValue: form.middleName
          })"
        ></mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('lastName') }}</div>
        <mt-field
          :placeholder="$t('lastNamePlaceholder')"
          :state="lastNameState"
          v-model="form.lastName"
          @blur.native.capture="BEHAVIOR_ADD({
            id: 'P02_C03_I_LASTNAME',
            newValue: form.lastName
          })"
        ></mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('email') }}</div>
        <mt-field
          :placeholder="$t('emailPlaceholder')"
          :state="emailState"
          v-model="form.email"
          @blur.native.capture="BEHAVIOR_ADD({
            id: 'P02_C04_I_EMAIL',
            newValue: form.email
          })"
        ></mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('birthday') }}</div>
        <mt-cell class="cell" @click.native="onSelectDate">
          <div v-if="form.date" class="date">{{ form.date }}</div>
          <div v-else>{{ $t('birthdayPlaceholder') }}</div>
          <span class="mint-field-state" v-if="dateState" :class="['is-' + dateState]">
            <i class="mintui" :class="['mintui-field-' + dateState]"></i>
          </span>
          <i class="iconfont">&#xe62a;</i>
        </mt-cell>
      </div>
      <div class="item">
        <div class="title">{{ $t('gender') }}</div>
        <div style="text-align: center;">
          <span
            class="mint-radio-box"
            @click="form.sex = 'male'; BEHAVIOR_ADD({
              id: 'P02_C05_R_SEX',
              newValue: form.sex
            })"
          >
            <span class="mint-radio">
              <input class="mint-radio-input" type="radio" v-model="form.sex" value="male">
              <span class="mint-radio-core"></span>
            </span>
            <span class="mint-radio-label">{{ $t('male') }}</span>
          </span>
          <span
            @click="form.sex = 'female'; BEHAVIOR_ADD({
              id: 'P02_C05_R_SEX',
              newValue: form.sex
            })"
          >
            <span class="mint-radio">
              <input class="mint-radio-input" type="radio" v-model="form.sex" value="female">
              <span class="mint-radio-core"></span>
            </span>
            <span class="mint-radio-label">{{ $t('female') }}</span>
          </span>

          <span style="vertical-align: sub;" class="mint-field-state" v-if="sexState" :class="['is-' + sexState]">
            <i class="mintui" :class="['mintui-field-' + sexState]"></i>
          </span>
        </div>
      </div>
      <div class="item">
        <div class="title">{{ $t('marriage') }}</div>
        <mt-field
          :placeholder="$t('marriagePlaceholder')"
          readonly
          :state="maritalStatusState"
          v-model="(Enum.married.filter(e => e.code == form.maritalStatus)[0]||{}).name"
          @click.native="onPopupPicker(Enum.married, (e) => {
            form.maritalStatus = e.code;
            BEHAVIOR_ADD({
              id: 'P02_C06_S_MARITAL',
              newValue: form.maritalStatus
            })
          })"
        >
          <mt-spinner v-if="Enum.marriedLoading" type="snake" color="#F15234" :size="16"></mt-spinner>
          <i v-else class="iconfont">&#xe62a;</i>
        </mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('addressSelect1') }}</div>
        <mt-field
          :placeholder="$t('addressPlaceholder')"
          readonly
          :state="homeAddrProvinceCodeState"
          v-model="(Enum.prov.filter(e => e.code == form.homeAddrProvinceCode)[0]||{}).name"
          @click.native="onPopupPicker(Enum.prov, (e) => {
            form.homeAddrProvinceCode = e.code;
            BEHAVIOR_ADD({
              id: 'P02_C07_S_HOMEPROVINCE',
              newValue: form.homeAddrProvinceCode
            })
          })"
        >
          <mt-spinner v-if="Enum.provLoading" type="snake" color="#F15234" :size="16"></mt-spinner>
          <i v-else class="iconfont">&#xe62a;</i>
        </mt-field>
      </div>

      <div class="item">
        <div class="title">{{ $t('addressSelect2') }}</div>
        <mt-field
          :placeholder="$t('addressPlaceholder')"
          readonly
          :state="homeAddrCityCodeState"
          v-model="(Enum.city.filter(e => e.code == form.homeAddrCityCode)[0]||{}).name"
          @click.native="onPopupPicker(Enum.city, (e) => {
            form.homeAddrCityCode = e.code;
            BEHAVIOR_ADD({
              id: 'P02_C08_S_HOMECITY',
              newValue: form.homeAddrCityCode
            })
          })"
        >
          <mt-spinner v-if="Enum.cityLoading" type="snake" color="#F15234" :size="16"></mt-spinner>
          <i v-else class="iconfont">&#xe62a;</i>
        </mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('houseNo') }}</div>
        <mt-field
          :placeholder="$t('houseNoPlaceholder')"
          :state="homeAddrDetailState"
          v-model="form.homeAddrDetail"
          @blur.native.capture="BEHAVIOR_ADD({
            id: 'P02_C09_S_MOBILETYPE',
            newValue: form.homeAddrDetail
          })"
        ></mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('degree') }}</div>
        <mt-field
          :placeholder="$t('degreePlaceholder')"
          readonly
          :state="educationCodeState"
          v-model="(Enum.educated.filter(e => e.code == form.educationCode)[0]||{}).name"
          @click.native="onPopupPicker(Enum.educated, (e) => {
            form.educationCode = e.code;
            BEHAVIOR_ADD({
              id: 'P02_C10_R_EDUCATION',
              newValue: form.educationCode
            })
          })"
        >
          <mt-spinner v-if="Enum.educatedLoading" type="snake" color="#F15234" :size="16"></mt-spinner>
          <i v-else class="iconfont">&#xe62a;</i>
        </mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('loanPurpose') }}</div>
        <mt-field
          :placeholder="$t('loanPurposePlaceholder')"
          readonly
          :state="loanPurposeState"
          v-model="(Enum.loanPurpose.filter(e => e.code == form.loanPurpose)[0]||{}).name"
          @click.native="onPopupPicker(Enum.loanPurpose, (e) => {
            form.loanPurpose = e.code;
            BEHAVIOR_ADD({
              id: 'P02_C11_R_LOANPURPOSE',
              newValue: form.loanPurpose
            })
          })"
        >
          <mt-spinner v-if="Enum.loanPurposeLoading" type="snake" color="#F15234" :size="16"></mt-spinner>
          <i v-else class="iconfont">&#xe62a;</i>
        </mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('religion') }}</div>
        <mt-field
          :placeholder="$t('religionPlaceholder')"
          readonly
          :state="religionState"
          v-model="(Enum.religion.filter(e => e.code == form.religionCode)[0]||{}).name"
          @click.native="onPopupPicker(Enum.religion, (e) => {
            form.religionCode = e.code;
            BEHAVIOR_ADD({
              id: 'P02_C12_R_RELIGION',
              newValue: form.religion
            })
          })"
        >
          <mt-spinner v-if="Enum.religionLoading" type="snake" color="#F15234" :size="16"></mt-spinner>
          <i v-else class="iconfont">&#xe62a;</i>
        </mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('phone') }}</div>
        <div class="phone">
          <mt-field
            :placeholder="$t('phonePlaceholder')"
            disabled
            :value="user.phone"
          ></mt-field>
        </div>
      </div>
      <div class="item">
        <div class="title optional">{{ $t('backupMobileNumber') }}</div>
        <div class="phone">
          <mt-field
            :placeholder="$t('phonePlaceholder')"
            :state="otherPhoneState"
            v-model="form.otherPhone"
            type="tel"
            v-mask="'#### #### ##'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P02_C13_I_otherPhone',
              newValue: form.otherPhone
            })"
          ></mt-field>
        </div>
      </div>
      <div class="item">
        <div class="title optional">{{ $t('facebook') }}</div>
        <mt-field
          :placeholder="$t('facebookPlaceholder')"
          :state="facebookState"
          v-model="form.facebook"
          @blur.native.capture="BEHAVIOR_ADD({
            id: 'P02_C15_I_FACEBOOK',
            newValue: form.facebook
          })"
        ></mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('barangay') }}</div>
        <mt-field
          :placeholder="$t('barangayPlaceholder')"
          :state="homeBarangayState"
          v-model="form.homeBarangay"
          @blur.native.capture="BEHAVIOR_ADD({
            id: 'P02_C14_I_HOMEBARANGAY',
            newValue: form.homeBarangay
          })"
        ></mt-field>
      </div>

    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("nextStep")
      }}</mt-button>
    </div>
    <mt-datetime-picker
      class="date"
      ref="picker"
      type="date"
      :value="datetimeValue"
      :year-format="`{value}`"
      :month-format="`{value}`"
      :date-format="`{value}`"
      :start-date="new Date('1900-01-01')"
      :end-date="new Date()"
      :cancelText="$t('cancel')"
      :confirmText="$t('confirm')"
      @confirm="handleConfirm"
    >
    </mt-datetime-picker>
    <popup-picker ref="popupPicker" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { nameRules, emptyRules, emailRules } from "../../utils/rules";
import { applyStep } from '../../utils/api'
import popupPicker from '@/components/popupPicker.vue'
import callPhone from '@/components/callPhone.vue'
import stepItem from '../../components/stepItem.vue'
import Moment from 'moment'
import mixin from './mixin'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../../utils/gtm.js'
export default {
  name: 'Realnameauth',
  mixins: [mixin],
  components: {
    popupPicker,
    callPhone,
    stepItem,
  },
  data() {
    return {
      pickerVisible: false,
      isCheck: false,
      form: {
        firstName: '', // 名字
        middleName: '', // 中间名
        lastName: '', // 姓
        email: '', // 邮箱
        date: '', // 生日
        sex: 'male', // 性别
        educationCode: '', // 学历
        maritalStatus: '', // 婚姻状况
        homeAddrProvinceCode: '', // 省市
        homeAddrCityCode: '', // 区
        homeAddrDetail: '', // 房屋号
        loanPurpose: '', // 贷款目的
        religionCode: '', // 宗教
        otherPhone: '', // 备用手机号
        facebook: '', // facebook
        homeBarangay: '', //
      }
    }
  },
  computed: {
    ...mapState(["apply", "user"]),
    firstNameState() {
      this.onStorageItem('firstName')
      return this.isCheck ? nameRules(this.form.firstName) : null;
    },
    middleNameState() {
      this.onStorageItem('middleName')
      // return this.isCheck ? nameRules(this.form.middleName) : null;
      return null;
    },
    lastNameState() {
      this.onStorageItem('lastName')
      return this.isCheck ? nameRules(this.form.lastName) : null;
    },
    emailState() {
      this.onStorageItem('email')
      return this.isCheck ? emailRules(this.form.email) : null;
    },
    dateState() {
      this.onStorageItem('date')
      return this.isCheck ? emptyRules(this.form.date) : null;
    },
    sexState() {
      this.onStorageItem('sex')
      return this.isCheck ? emptyRules(this.form.sex) : null;
    },
    educationCodeState() {
      this.onStorageItem('educationCode')
      return this.isCheck ? emptyRules(this.form.educationCode) : null;
    },
    maritalStatusState() {
      this.onStorageItem('maritalStatus')
      return this.isCheck ? emptyRules(this.form.maritalStatus) : null;
    },
    homeAddrProvinceCodeState() {
      this.onStorageItem('homeAddrProvinceCode')
      return this.isCheck ? emptyRules(this.form.homeAddrProvinceCode) : null;
    },
    homeAddrCityCodeState() {
      this.onStorageItem('homeAddrCityCode')
      return this.isCheck ? emptyRules(this.form.homeAddrCityCode) : null;
    },
    homeAddrDetailState() {
      this.onStorageItem('homeAddrDetail')
      return this.isCheck ? emptyRules(this.form.homeAddrDetail) : null;
    },
    loanPurposeState() {
      this.onStorageItem('loanPurpose')
      return this.isCheck ? emptyRules(this.form.loanPurpose) : null;
    },
    religionState() {
      this.onStorageItem('religionCode')
      return this.isCheck ? emptyRules(this.form.religionCode) : null;
    },
    otherPhoneState() {
      this.onStorageItem('otherPhone')
      // return this.isCheck ? phoneRules(this.form.otherPhone) : null;
      return null;
    },
    facebookState() {
      this.onStorageItem('facebook')
      return null;
    },
    homeBarangayState() {
      this.onStorageItem('homeBarangay')
      return this.isCheck ? emptyRules(this.form.homeBarangay) : null;
    },
    datetimeValue() {
      return Moment(this.form.date || '01.01.1998', 'MM.DD.YYYY').toDate()
    }
  },
  watch: {
    // 监听省市发生变化重置区内容
    'form.homeAddrProvinceCode': {
      handler(val) {
        if (!val) return
        this.GET_DISTRICT_CITY(val).then(() => {
          let length = this.Enum.city.filter(e => e.code == this.form.homeAddrCityCode).length
          if (!length) {
            this.form.homeAddrCityCode = ''
          }
        })
      },
      immediate: true
    },
  },
  created() {
    gtmPageView(this)
    Object.keys(this.form).forEach((val) => {
      this.form[val] = this.apply[val] || ''
    })
    if (!this.form.sex) {
      this.form.sex = 'male'
    }
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P02_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P02_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER", "SET_FROM_OTHER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    init() {
      this.GET_EDUCATION() // 获取学历
      this.GET_MARITAL_STATUS() // 获取婚姻状况
      this.GET_DISTRICT_PROV() // 获取省市
      this.GET_LOAN_PURPOSE() // 获取贷款目的
      this.GET_RELIGION() // 获得宗教信仰
    },
    // 选择生日
    onSelectDate() {
      this.$refs.picker.open();
    },
    handleConfirm(date) {
      this.form.date = Moment(date).format('MM.DD.YYYY')
      this.BEHAVIOR_ADD({
        id: 'P02_C05_I_BIRTHDATE',
        newValue: this.form.date
      })
    },
    // 校验输入内容
    validation(list) {
      if (list.indexOf('firstName') >= 0 && this.firstNameState != "success") {
        return this.firstNameState == "warning"
          ? this.$t("firstNameWarning")
          : this.$t("firstNameError");
      }
      if (list.indexOf('middleName') >= 0 && this.middleNameState != "success") {
        return this.middleNameState == "warning"
          ? this.$t("middleNameWarning")
          : this.$t("middleNameError");
      }
      if (list.indexOf('lastName') >= 0 && this.lastNameState != "success") {
        return this.lastNameState == "warning"
          ? this.$t("lastNameWarning")
          : this.$t("lastNameError");
      }
      if (list.indexOf('email') >= 0 && this.emailState != "success") {
        return this.emailState == "warning"
          ? this.$t('emailPlaceholder')
          : this.$t('emailError')
      }
      if (list.indexOf('date') >= 0 && this.dateState != "success") {
        return this.$t('birthdayError')
      }
      if (list.indexOf('sex') >= 0 && this.sexState != "success") {
        return this.$t('genderError')
      }
      if (list.indexOf('educationCode') >= 0 && this.educationCodeState != "success") {
        return this.$t('degreeError')
      }
      if (list.indexOf('maritalStatus') >= 0 && this.maritalStatusState != "success") {
        return this.$t('marriageError')
      }
      if (list.indexOf('homeAddrProvinceCode') >= 0 && this.homeAddrProvinceCodeState != "success") {
        return this.$t('addressError')
      }
      if (list.indexOf('homeAddrCityCode') >= 0 && this.homeAddrCityCodeState != "success") {
        return this.$t('addressError')
      }
      if (list.indexOf('homeAddrDetail') >= 0 && this.homeAddrDetailState != "success") {
        return this.$t('houseNoError')
      }
      if (list.indexOf('loanPurpose') >= 0 && this.loanPurposeState != "success") {
        return this.$t('loanPurposeError')
      }
      if (list.indexOf('religionCode') >= 0 && this.religionState != "success") {
        return this.$t('religionError')
      }
      if (list.indexOf('otherPhone') >= 0 && this.otherPhoneState != "success") {
        return this.$t('phoneError')
      }
      if (list.indexOf('homeBarangay') >= 0 && this.homeBarangayState != "success") {
        return this.$t('barangayError')
      }
    },
    // 提交下一步
    async submit() {
      this.isCheck = true;
      var error = this.validation([
        'firstName',
        // 'middleName',
        'lastName',
        'email',
        'date',
        'sex',
        'educationCode',
        'maritalStatus',
        'homeAddrProvinceCode',
        'homeAddrCityCode',
        'homeAddrDetail',
        'loanPurpose',
        'religionCode',
        // 'otherPhone',
        'homeBarangay',
      ]);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('02_REAL_NAME_AUTH', { content_name: '02_REAL_NAME_AUTH' })
        }
        // Google Analytics  REAL_NAME_AUTH
        if (localStorage['ga']) {
          window.gtag('event', 'REAL_NAME_AUTH')
        }
      } catch(e) {
        console.log(e)
      }
      var thirdInfos = [];
      thirdInfos.push({
        "authPhone": this.form.facebook,
        "authType": "",
        "isAuth": "Y",
        "thirdCode": "Facebook",
        "thirdName": "Facebook"
      });
      let data = {
        currentStep: 3,
        totalSteps: 8,

        applyId: this.user.applyId,
        homeAddrProvince: (this.Enum.prov.filter(e => e.code == this.form.homeAddrProvinceCode)[0]||{}).name,
        homeAddrCity: (this.Enum.city.filter(e => e.code == this.form.homeAddrCityCode)[0]||{}).name,
        education: (this.Enum.educated.filter(e => e.code == this.form.educationCode)[0]||{}).name,
        religionValue: (this.Enum.religion.filter(e => e.code == this.form.religionCode)[0]||{}).name,

        ...this.form,
        name: `${this.form.firstName} ${this.form.middleName} ${this.form.lastName}`,
        phone: this.user.phone,
        otherPhone: this.form.otherPhone.replace(/\s/g, ''),
        birth: this.form.date,
        thirdInfos: thirdInfos,
        email: this.form.email.replace(/\s/g, ''),
      }
      await this.$axios({
        method: "post",
        url: applyStep,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              applyId: e.body.applyId
            })
            if (e.body.fromOther === 'Y') {
              this.SET_FROM_OTHER('Y')
              this.$router.push('/step/relationshipauth')
            } else {
              this.SET_FROM_OTHER('N')
              this.$router.push('/step/jobauth')
            }
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done()
      branch.logEvent('02_REAL_NAME_AUTH', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.realnameauth {
  background-color: #FBFBFF;
  .content {
    margin: 10px;
    .item {
      margin-top: 20px;
      .mint-cell {
        background: white;
        /deep/ .mint-cell-wrapper {
          background: none;
        }
      }
      .title {
        margin: 6px 15px;
        font-size: 16px;
        color: #1D1D1D;
        &.optional {
          &::after {
            content: '';
          }
        }
        &::after {
          content: '*';
          font-size: 14px;
          vertical-align: top;
          color: #E92121;
        }
      }
      .phone {
        border-radius: 6px;
        display: flex;
        background-color: white;
        .mint-cell {
          flex: 1;
        }
        &::before {
          content: '+52';
          color: #111111;
          padding-left: 10px;
          line-height: 46px;
        }
      }
      .mint-field {
        border-radius: 6px;
        min-height: 46px;
      }
    }
    .cell {
      .date {
        color: black;
      }
      /deep/ .mint-cell-label {
        font-size: 16px;
        color: inherit;
      }
      /deep/ .mint-cell-title {
        flex: none;
      }
      /deep/ .mint-cell-value {
        padding: 0 2px;
        color: #818181;
        flex: 1;
        display: flex;
        div {
          flex: 1;
        }
      }
    }
    .iconfont {
      margin: 0 5px;
      color: #F15234;
    }
    .mint-radio-box {
      margin-right: 40px;
    }
  }
  .bottom {
    padding: 40px 20px;
    .mint-button {
      border-radius: 6px;
    }
  }
  .date {
    /deep/ .picker-items {
      display: block;
      .picker-slot {
        display: inline-block;
        width: 33.3%;
        &:nth-child(1) {
          float: right;
        }
      }
    }
  }
  
}
</style>